import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import * as React from 'react';
import { TextButton, Style as ButtonStyle, Type as ButtonType } from '../elements/text-button';

type Properties = {
    className: string;
};

const Packages = ({ className }: Properties): React.ReactElement => {
    return <div className={'mx-auto'}>
        <div className={classNames('grid md:grid-cols-3 items-start gap-8', className)}>
            <div className={'p-9 rounded-md bg-caribbean-50'}>
                <span className={'text-xl font-bold mb-4'}>Pakket Fit</span>
                <div className={'prose'}>
                    <ul>
                        <li>Intakegesprek</li>
                        <li>3 terugkoppelmomenten</li>
                        <li>1 maandmenu</li>
                        <li>4 groepslessen powerwalken</li>
                        <li>Eindevaluatie</li>
                    </ul>
                    <span className={'inline-block px-2 bg-lemon rounded-md font-bold'}>Pakketprijs: € 189</span>
                </div>
            </div>
            <div className={'p-9 rounded-md bg-caribbean-50'}>
                <span className={'text-xl font-bold mb-4'}>Pakket Fitter</span>
                <div className={'prose'}>
                    <ul>
                        <li>Intakegesprek</li>
                        <li>6 terugkoppelmomenten</li>
                        <li>1 maandmenu</li>
                        <li>8 groepslessen powerwalken</li>
                        <li>Eindevaluatie</li>
                    </ul>
                    <span className={'inline-block clear px-2 bg-lemon rounded-md font-bold'}>Pakketprijs: € 299</span>
                    <span className={'block text-xs text-gray-500 mt-2'}>inclusief Fitfoodforty rugzakje</span>
                </div>
            </div>
            <div className={'p-9 rounded-md bg-caribbean-50'}>
                <span className={'text-xl font-bold mb-4'}>Pakket Topfit</span>
                <div className={'prose'}>
                    <ul>
                        <li>Intakegesprek</li>
                        <li>6 terugkoppelmomenten</li>
                        <li>1 maandmenu</li>
                        <li>6 individuele (wandel-)gesprekken leefstijlcoaching</li>
                        <li>12 groepslessen powerwalken</li>
                        <li>Eindevaluatie</li>
                    </ul>
                    <span className={'inline-block clear px-2 bg-lemon rounded-md font-bold'}>Pakketprijs: € 349</span>
                    <span className={'block text-xs text-gray-500 mt-2'}>inclusief Fitfoodforty rugzakje en fitnesselastieken</span>
                </div>
            </div>
        </div>
        <div className={'text-center mt-8'}>
            <TextButton
                type={ButtonType.Link}
                style={ButtonStyle.Secondary}
                url={'mailto:vicky@fitfoodforty.nl'}
                caption={'Interesse of vragen? Mail me!'}
                className={'mt-6'}
            />
            <div className={'mt-4 text-xs text-gray-700'}>
                Of app me via WhatsApp:{' '}
                <a
                    href={'https://wa.me/31641777848'}
                    target={'_blank'}
                    title={'App me gerust via WhatsApp!'}
                    rel={'noreferrer'}
                    className={'underline'}>
                    06 4177 7848
                </a>{' '}
                <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'ml-0.5'} />
            </div>
        </div>
    </div>;
};

Packages.propTypes = {
    className: PropTypes.string,
};

Packages.defaultProps = {
    className: '',
};

export { Packages };
